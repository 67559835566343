<template>
    <div class="Landing">
      <navBar></navBar>
      <textEdit title-user="Welcome Text" title-intern-prop="welcome_at_ima" site-prop="home"></textEdit>
      <listEdit title-user="News" title-intern-prop="news" site-prop="home" description-prop="News are sorted by date." date-prop=true></listEdit>
      <textEdit title-user="Audition Deadlines Text" title-intern-prop="audition_deadlines_text" site-prop="home" subheader-prop=True></textEdit>
      <textEdit title-user="Audition Text" title-intern-prop="audition_text" site-prop="home"></textEdit>
      <applyDates title-user="Audition Dates"></applyDates>
      <dateDates title-user="Dates"></dateDates>

    </div>
</template>

<script>
  import navBar from '@/components/NavBar.vue';
  import textEdit from '@/components/TextEdit.vue';
  import listEdit from '@/components/ListEdit.vue';
  import applyDates from '@/components/Audition.vue';
  import dateDates from '@/components/Date.vue';
    export default {
      data: function () {
        return{

        }
      },
      methods: {

      },
      components: {
        navBar,
        textEdit,
        listEdit,
        applyDates,
        dateDates
      },
    };
</script>

<style scoped>

</style>

<template>
  <div class="date">
    <div class="content">

      <p class="md-headline">{{ titleUser }}</p>
    <!--Dropdown-->
      <div class="head" v-if="showHead">
        <md-field>
          <label for="date">Date</label>
          <md-select @md-selected="addDateDiv = false" v-model="currentDateIndex" name="Date" id="date">
            <md-option v-for="(date, index) in date" v-bind:key="index" :value="index">{{ `${(new Date(date.en.date).toLocaleDateString())} - ${date.en.text}`}}</md-option>
          </md-select>
        </md-field>
        <md-button class="md-primary md-raised" @click="showAddDateDiv()">Add Date</md-button>
      </div>

      <!-- See chosen date data -->
      <div class="dateData" v-if="!addDateDiv && currentDateIndex != null">
        <!-- Date-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Ort</label>
                <md-input v-model="date[currentDateIndex].de.location" ></md-input>
              </md-field>
              <md-field>
                <label>Text</label>
                <md-input v-model="date[currentDateIndex].de.text"></md-input>
              </md-field>
              <br />
              <span>Datum<v-date-picker
                v-model="date[currentDateIndex].de.date"
              /></span>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
              <label>Location</label>
              <md-input v-model="date[currentDateIndex].en.location" ></md-input>
              </md-field>
              <md-field>
                <label>Text</label>
                <md-input v-model="date[currentDateIndex].en.text"></md-input>
              </md-field>
              <br />
              Date <v-date-picker
              v-model="date[currentDateIndex].en.date"
            />
            </td>
          </tr>
        </table>

        <md-dialog-confirm
          :md-active.sync="newDateDialogActive"
          :md-title="'Delete date ' + date[currentDateIndex].date + '?'"
          md-content="All date of this date will be completely deleted!"
          md-confirm-text="Delete"
          md-cancel-text="Hell no"
          @d-cancel="deleteDateDialogActive = false"
          @md-confirm="deleteDate(date[currentDateIndex]._id)" />

        <md-button class="md-accent md-raised" @click="newDateDialogActive = true">Delete Date</md-button>
        <md-button class="md-primary md-raised" @click="putDate()">Save Changes</md-button>

      </div>

      <!-- Add Date -->
      <div class="addDate" v-if="addDateDiv">
        <!-- Date-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Ort</label>
                <md-input v-model="newDate.de.location" ></md-input>
              </md-field>
              <md-field>
                <label>Text</label>
                <md-input v-model="newDate.de.text" ></md-input>
              </md-field>
              <br />
              Datum<v-date-picker
              v-model="newDate.de.date"
            />
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Location</label>
                <md-input v-model="newDate.en.location" ></md-input>
              </md-field>
              <md-field>
                <label>Text</label>
                <md-input v-model="newDate.en.text"></md-input>
              </md-field>
              <br />
              Date <v-date-picker
              v-model="newDate.en.date"
            />
            </td>
          </tr>
        </table>


          <md-dialog-confirm
            :md-active.sync="newDateDialogActive"
            md-title="Do you want to add a new date?"
            md-content="A new data set will be created."
            md-confirm-text="Save"
            md-cancel-text="Cancel"
            @d-cancel="newDateDialogActive = false"
            @md-confirm="pushNewDate()" />

          <md-button class="md-primary md-raised" @click="newDateDialogActive = true">Add Date</md-button>

          <md-dialog-confirm
            :md-active.sync="newDateCancelDialogActive"
            md-title="Do you want to delete all entered data?"
            md-content="All data you entered will be deleted."
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @d-cancel="newDateCancelDialogActive = false"
            @md-confirm="resetSite()" />

          <md-button class="md-accent md-raised" @click="newDateCancelDialogActive = true">Cancel</md-button>
          <loading :active.sync="query"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
        <md-snackbar md-position="center" :md-duration="400000" :md-active="showSnackbar" md-persistent>
          <span>{{ snackbarContent }}</span>
        </md-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';

    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import config from '@/config/const.js'

    export default {
      name: "dateDates",
      props: {
          titleUser: String
      },
      data () {
            return {
              config: config,
              dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
              query: false,
              newDateDialogActive: false,
              newDateCancelDialogActive: false,
              deleteDateDialogActive: false,
              addDateDiv: false,
              showHead: true,
              currentDateIndex: null,

              showSnackbar: false,
              snackbarContent: "",

              date: null,
              newDate: {
                de: {
                  location: "",
                  date: "",
                  deadline: new Date(),
                },
                en: {
                  location: "",
                  date: "",
                  deadline: new Date(),
                }
              },

            }
        },
        methods:{
          niceDates: function (date) {
            return new Date(date).toLocaleDateString('en', this.dateOptions)
          },
          pushNewDate(){
            this.query = true;
            let me = this;
            axios.post( me.config.SERVER_ADDRESS + '/date/',me.newDate)
                .then(function (response) {
                  me.loadData()
                  me.resetSite()
                  me.snackbarContent = response.data.message
                  me.showSnackbar = true
                  me.query = false;
                })
                .catch(function (error) {
                  me.query = false;
                  alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
                })
          },
          putDate(){
            this.query = true;
            let me = this;
            axios.put( me.config.SERVER_ADDRESS + '/date/' + me.date[me.currentDateIndex]._id,me.date[me.currentDateIndex])
              .then(function (response) {
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.loadData()
                me.resetSite()
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              })
          },
          deleteDate(id){
            this.query = true;

            let me = this;
            axios.delete( me.config.SERVER_ADDRESS + '/date/' + id)
              .then(function (response) {
                me.loadData();
                me.resetSite();
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          resetSite(){
            this.newDateDialogActive = false
            this.newDateCancelDialogActive = false
            this.addDateDiv = false
            this.showHead = true
            this.currentDateIndex = null
            this.newDate = {
              de: {
                location: "",
                text: "",
                date: new Date(),
              },
              en: {
                location: "",
                text: "",
                date: new Date(),
              }
            }
          },
          showAddDateDiv(){
            this.addDateDiv = true
            this.showHead = false
          },
          loadData: function () {
            const me = this
            axios.get( me.config.SERVER_ADDRESS + '/date')
              .then(function (response) {
                me.date = response.data.map(d =>{
                  d.de.date = new Date(d.de.date)
                  d.en.date = new Date(d.en.date)
                  return d
                })
              })
              .catch(function (error) {
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          makeDate: function () {
            for(let i = 0; i < this.date.lenght; i ++){
              this.date[i].deadline = new Date(this.date[i].deadline)
            }
          }
        },
        components: {
          Loading
        },
        mounted(){
          this.loadData();
          this.makeDate();
        }
    }
</script>
<style scoped>
  .content{
    margin: 2% 5% 0 5%;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  td{
    padding: 1%;
    width: 50%;
  }
  .noMargin{
    margin: 0px;
  }
</style>

<template>
  <div class="audition">
    <div class="content">

      <p class="md-headline">{{ titleUser }}</p>
    <!--Dropdown-->
      <div class="head" v-if="showHead">
        <md-field>
          <label for="audition">Audition</label>
          <md-select @md-selected="addAuditionDiv = false" v-model="currentAuditionIndex" name="Audition" id="audition">
            <md-option v-for="(audition, index) in audition" v-bind:key="index" :value="index">{{ audition.en.location }}</md-option>
          </md-select>
        </md-field>
        <md-button class="md-primary md-raised" @click="showAddAuditionDiv()">Add Audition</md-button>
      </div>

      <!-- See chosen audition data -->
      <div class="auditionData" v-if="!addAuditionDiv && currentAuditionIndex != null">
        <!-- Audition-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Ort</label>
                <md-input v-model="audition[currentAuditionIndex].de.location" ></md-input>
              </md-field>
              <md-field>
                <label>Datum des Vorsingens</label>
                <md-input v-model="audition[currentAuditionIndex].de.date"></md-input>
              </md-field>
              <br />
              <span>Bewebung Deadline: <v-date-picker
                v-model="audition[currentAuditionIndex].de.deadline"
              /></span>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
              <label>Location</label>
              <md-input v-model="audition[currentAuditionIndex].en.location" ></md-input>
              </md-field>
              <md-field>
                <label>Audition Date</label>
                <md-input v-model="audition[currentAuditionIndex].en.date"></md-input>
              </md-field>
              <br />
              Audition Deadline: <v-date-picker
              v-model="audition[currentAuditionIndex].en.deadline"
            />
            </td>
          </tr>
        </table>

        <md-dialog-confirm
          :md-active.sync="newAuditionDialogActive"
          :md-title="'Delete audition ' + audition[currentAuditionIndex].audition + '?'"
          md-content="All audition of this audition will be completely deleted!"
          md-confirm-text="Delete"
          md-cancel-text="Hell no"
          @d-cancel="deleteAuditionDialogActive = false"
          @md-confirm="deleteAudition(audition[currentAuditionIndex]._id)" />

        <md-button class="md-accent md-raised" @click="newAuditionDialogActive = true">Delete Audition</md-button>
        <md-button class="md-primary md-raised" @click="putAudition()">Save Changes</md-button>

      </div>

      <!-- Add Audition -->
      <div class="addAudition" v-if="addAuditionDiv">
        <!-- Audition-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Ort</label>
                <md-input v-model="newAudition.de.location" ></md-input>
              </md-field>
              <md-field>
                <label>Datum des Vorsingens</label>
                <md-input v-model="newAudition.de.date" ></md-input>
              </md-field>
              <br />
              Bewebung Deadline: <v-date-picker
              v-model="newAudition.de.deadline"
            />
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Location</label>
                <md-input v-model="newAudition.en.location" ></md-input>
              </md-field>
              <md-field>
                <label>Audition Date</label>
                <md-input v-model="newAudition.en.date"></md-input>
              </md-field>
              <br />
              Audition Deadline: <v-date-picker
              v-model="newAudition.en.deadline"
            />
            </td>
          </tr>
        </table>


          <md-dialog-confirm
            :md-active.sync="newAuditionDialogActive"
            md-title="Do you want to add a new audition?"
            md-content="A new data set will be created."
            md-confirm-text="Save"
            md-cancel-text="Cancel"
            @d-cancel="newAuditionDialogActive = false"
            @md-confirm="pushNewAudition()" />

          <md-button class="md-primary md-raised" @click="newAuditionDialogActive = true">Add Audition</md-button>

          <md-dialog-confirm
            :md-active.sync="newAuditionCancelDialogActive"
            md-title="Do you want to delete all entered data?"
            md-content="All data you entered will be deleted."
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @d-cancel="newAuditionCancelDialogActive = false"
            @md-confirm="resetSite()" />

          <md-button class="md-accent md-raised" @click="newAuditionCancelDialogActive = true">Cancel</md-button>
          <loading :active.sync="query"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
        <md-snackbar md-position="center" :md-duration="400000" :md-active="showSnackbar" md-persistent>
          <span>{{ snackbarContent }}</span>
        </md-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';

    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import config from '@/config/const.js'

    export default {
      name: "auditionDates",
      props: {
          titleUser: String
      },
      data () {
            return {
              config: config,
              dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
              query: false,
              newAuditionDialogActive: false,
              newAuditionCancelDialogActive: false,
              deleteAuditionDialogActive: false,
              addAuditionDiv: false,
              showHead: true,
              currentAuditionIndex: null,

              showSnackbar: false,
              snackbarContent: "",

              audition: null,
              newAudition: {
                de: {
                  location: "",
                  date: "",
                  deadline: new Date(),
                },
                en: {
                  location: "",
                  date: "",
                  deadline: new Date(),
                }
              },

            }
        },
        methods:{
          niceDates: function (date) {
            return new Date(date).toLocaleDateString('en', this.dateOptions)
          },
          pushNewAudition(){
            this.query = true;
            let me = this;
            axios.post( me.config.SERVER_ADDRESS + '/audition/',me.newAudition)
                .then(function (response) {
                  me.loadData()
                  me.resetSite()
                  me.snackbarContent = response.data.message
                  me.showSnackbar = true
                  me.query = false;
                })
                .catch(function (error) {
                  me.query = false;
                  alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
                })
          },
          putAudition(){
            this.query = true;
            let me = this;
            axios.put( me.config.SERVER_ADDRESS + '/audition/' + me.audition[me.currentAuditionIndex]._id,me.audition[me.currentAuditionIndex])
              .then(function (response) {
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.loadData()
                me.resetSite()
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              })
          },
          deleteAudition(id){
            this.query = true;

            let me = this;
            axios.delete( me.config.SERVER_ADDRESS + '/audition/' + id)
              .then(function (response) {
                me.loadData();
                me.resetSite();
                me.snackbarContent = response.data.message
                me.showSnackbar = true
                me.query = false;
              })
              .catch(function (error) {
                me.query = false;
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          resetSite(){
            this.newAuditionDialogActive = false
            this.newAuditionCancelDialogActive = false
            this.addAuditionDiv = false
            this.showHead = true
            this.currentAuditionIndex = null
            this.newAudition = {
              de: {
                location: "",
                date: "",
                deadline: new Date(),
              },
              en: {
                location: "",
                date: "",
                deadline: new Date(),
              }
            }
          },
          showAddAuditionDiv(){
            this.addAuditionDiv = true
            this.showHead = false
          },
          loadData: function () {
            const me = this
            axios.get( me.config.SERVER_ADDRESS + '/audition')
              .then(function (response) {
                me.audition = response.data.map(a => {
                  a.de.deadline = new Date(a.de.deadline)
                  a.en.deadline = new Date(a.en.deadline)
                  return a
                })

              })
              .catch(function (error) {
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          makeDate: function () {
            for(let i = 0; i < this.audition.lenght; i ++){
              this.audition[i].deadline = new Date(this.audition[i].deadline)
            }
          }
        },
        components: {
          Loading
        },
        mounted(){
          this.loadData();
          this.makeDate();
        }
    }
</script>
<style scoped>
  .content{
    margin: 2% 5% 0 5%;
  }
  .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  td{
    padding: 1%;
    width: 50%;
  }
  .noMargin{
    margin: 0px;
  }
</style>
